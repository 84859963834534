.login-body{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - var(--nav-height));
}

input{
    width: calc(100% - 20px);
    border: none;
    border-radius: 500px;
    margin: 10px;
    padding: 10px 20px;
}

.login-form-div{
    width: min(400px, 90vw);
    padding: 24px;
    background-color: var(--box-b-color);
    box-shadow: var(--box-shadow);
    /* backdrop-filter: blur(2px); */
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
/* 
.login-form-div{
    width: min(400px, 90vw);
    padding: 24px;
    background-color: #beddff;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
} */

.loading{
    width: min(400px, 90vw);
    height: 100%;
    padding: 24px;
    background-color: #beddff;
    border-radius: 35px;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
}

.inputs{
    width: 100%;
}

.email-auth-buttons{
    width: 100%;
}



button{
    background-color: #f8fcff;
}


.login-body .login-button,.signup-button {
    width: calc(50% - 16px);
}

.google-button, .resend-button {
    width: calc(100% - 16px);
}

.message{
    color: rgb(218, 0, 0);
    font-size: 16px;
}


@media (hover:hover){
    
button:hover{
    background-color: rgb(0, 145, 255);
    color: white;
    transition: 200ms ease-in-out all;
}
}