body {
  margin: 0;
  padding: 0;
  font-family: Poppins, 'Roboto Condensed', Lato;
  /* font-family: Comfortaa,'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, 'Roboto Condensed', Lato;
  /* font-family: Comfortaa,'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    user-select: none;

}

.prevent-select{
  user-select: none;
}